import styled from "styled-components";

export const DescriptionStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5em;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-gap: 0em;
  }
`