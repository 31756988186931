import React, { useState } from "react"
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import SEO from '../components/SEO'
import styled from "styled-components"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import { HeaderStyles } from "../styles/HeaderStyles"
import { SublineStyles } from "../styles/SublineStyles"
import { DescriptionStyles } from "../styles/DescriptionStyles"

export default function Home({ data }) {
  const projects = data.projects.nodes


  return (
    <Layout>
      <SEO title={'Index'} />
      <HeaderStyles>DIGITAL DESIGN & DEV STUDIO</HeaderStyles>
      <SublineStyles>
        <span></span>
        <p>BASED IN CHICAGO. WE DESIGN AND BUILD SIMPLE APPS AND WEBSITES. <a href="mailTo:hello@gilperez.com">SAY HELLO.</a></p>
        <span></span>
      </SublineStyles>
      <div>
        {projects.map((project) => {
          return (
            <div key={project.id} >
              <div>
                <Link to="https://apps.apple.com/au/app/simple-split-tip-calculator/id1543613936">
                  <GatsbyImage className="image" image={project.frontmatter.thumb.childImageSharp.gatsbyImageData} alt={project.frontmatter.title} />
                </Link>
                <DescriptionStyles>
                  <div dangerouslySetInnerHTML={{ __html: project.html }} />
                </DescriptionStyles>
              </div>
            </div>
          )
        })}

      </div>
    </Layout>
  )
}

export const query = graphql`
query ProjectsList {
  projects: allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}
                filter: {frontmatter: {published: {eq: true}}}) {
    nodes {
      html
      frontmatter {
        slug
        date
        title
        published
        thumb {
              childImageSharp {
                gatsbyImageData
           }
        }
      }
      id
    }
  }
}
`;